@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
  font-family: 'Outfit', sans-serif;
}

body {
  background: #EBEFF3;
}
